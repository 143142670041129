export class Emitter {
  constructor(game) {
    this.game = game;
    this.setup();
  }

  emit() {
    this.particles.emitParticleAt(1000, 700);
  }

  setup() {
    this.particles = this.game.add.particles("gems");
    this.particles.setPipeline("pixelPipeline_2");

    this.particles.createEmitter({
      frame: "BALLOT/ballot.png",
      angle: { min: 180, max: 194 },
      speed: { min: 1000, max: 1300 },
      quantity: 1,
      lifespan: 3000,
      alpha: { start: 1, end: 1 },
      scale: { start: 0.4, end: 0.9 },
      rotate: { start: 0, end: 360, ease: "Back.easeOut" },
      on: false,
    });

    this.particles.createEmitter({
      frame: "MISC/plusone.png",
      angle: { min: -90, max: -95 },
      speed: { min: 600, max: 800 },
      quantity: 1,
      lifespan: 500,
      alpha: { start: 1, end: 0 },
      scale: { start: 0.7, end: 0.8 },
      rotate: { start: 0, end: 10, ease: "Back.easeOut" },
      on: false,
    });
  }
}
