export class Hero {
  constructor(game) {
    this.game = game;
    this.setup();
  }

  setup() {
    this.sprite = this.game.add.sprite(
      989,
      710,
      "gems",
      "BIDEN/throw/throw0000.png"
    );

    this.shaker = this.game.plugins.get("rexShakePosition").add(this.sprite, {
      mode: 1,
      duration: Infinity,
      magnitude: 2,
      magnitudeMode: 0,
    });

    const throwFrames = this.game.anims.generateFrameNames("gems", {
      start: 0,
      end: 4,
      zeroPad: 4,
      prefix: "BIDEN/throw/throw",
      suffix: ".png",
    });

    const sleepFrames = this.game.anims.generateFrameNames("gems", {
      start: 0,
      end: 7,
      zeroPad: 4,
      prefix: "BIDEN/sleep/sleep",
      suffix: ".png",
    });

    this.snoreSound = this.game.sound.add("snore", {});

    this.game.anims.create({
      key: "throw",
      frames: throwFrames,
      frameRate: 26,
    });

    this.game.anims.create({
      key: "sleep",
      frames: sleepFrames,
      frameRate: 8,
      repeat: -1,
    });

    this.sprite.setScale(0.8);
    this.shaker.shake();
  }

  pixelate(active) {
    if (active) this.sprite.setPipeline("pixelPipeline_2");
  }

  stop() {
    this.sprite.anims.pause();
    this.shaker.pause();
    this.snoreSound.stop();
  }

  start() {
    this.sleep();
  }

  sleep() {
    this.sprite.anims.play("sleep");
    this.snoreSound.play({ repeat: -1, loop: true, volume: 0.1 });
  }
  throw() {
    this.sprite.anims.play("throw");
    this.snoreSound.stop();
  }
}
