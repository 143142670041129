import { GAME_WIDTH } from '../app';

export class Enemy {
  constructor(game, key, config) {
    this.game = game;
    this.key = key;
    this.direction = config.direction || "right";

    this.config = config;
    this.setup(config);
  }

  createEnemyTween(cb) {
    const numScreens = 2.7;
    const minBound = 0 - (GAME_WIDTH / 2) * (numScreens - 1);
    const maxBound = GAME_WIDTH + (GAME_WIDTH / 2) * (numScreens - 1);

    const newX =
      this.direction === "right"
        ? Math.random() * Math.abs(maxBound - this.sprite.x) + this.sprite.x
        : Math.random() * Math.abs(minBound - this.sprite.x) + minBound;

    this.direction = this.direction === "right" ? "left" : "right";
    const shouldFlip = newX < this.sprite.x;

    this.sprite.setScale(
      (this.config.flip && shouldFlip ? -1 : 1) * Math.abs(this.sprite.scaleX),
      this.sprite.scaleY
    );

    return this.game.tweens.add({
      targets: this.sprite,
      props: {
        x: {
          value: newX,
          duration: Math.max(Math.abs(this.sprite.x - newX) * 2.3, 500),
        },
      },
      ease: "Sine.easeInOut",
      onComplete: () => {
        cb(() => {
          if (this.game.state === "playing") {
            this.tweenX = this.createEnemyTween(cb);
          }
        });
      },
    });
  }

  setup(config) {
    this.sprite = this.game.add.sprite(
      (config.direction === "left" ? 1 : -1) * GAME_WIDTH * 1.5,
      720,
      "gems",
      `${this.key}/walk/walk0000.png`
    );

    const walkFrames = this.game.anims.generateFrameNames("gems", {
      start: 0,
      end: config.walk.length,
      zeroPad: 4,
      prefix: `${this.key}/walk/walk`,
      suffix: ".png",
    });

    this.game.anims.create({
      key: `${this.key}Walk`,
      frames: walkFrames,
      frameRate: config.walk.fps,
      repeat: -1,
    });

    if (config.look) {
      const lookFrames = this.game.anims.generateFrameNames("gems", {
        start: 0,
        end: config.look.length,
        zeroPad: 4,
        prefix: `${this.key}/look/look`,
        suffix: ".png",
      });

      this.game.anims.create({
        key: `${this.key}Look`,
        frames: lookFrames,
        frameRate: config.look.fps,
      });
    }

    if (config.end) {
      const endFrames = this.game.anims.generateFrameNames("gems", {
        start: 0,
        end: config.end.length,
        zeroPad: 4,
        prefix: `${this.key}/end/end`,
        suffix: ".png",
      });

      this.game.anims.create({
        key: `${this.key}End`,
        frames: endFrames,
        frameRate: config.end.fps,
        repeat: -1,
      });
    }

    this.tweenX = this.createEnemyTween((cb) => {
      if (config.look) {
        this.tweenY.pause();
        this.sprite.play(`${this.key}Look`);
        this.sprite.once(
          "animationcomplete",
          () => {
            this.sprite.play(`${this.key}Walk`);
            this.tweenY.resume();
            cb();
          },
          this.sprite
        );
      } else {
        cb();
      }
    });

    this.tweenY = this.game.tweens.add({
      targets: this.sprite,
      props: {
        y: { value: 690, duration: 300 },
      },
      yoyo: true,
      ease: "Sine.easeInOut",
      repeat: -1,
      onRepeat: () => {
        const inScreen =
          this.sprite.x < GAME_WIDTH - this.sprite.width / 4 &&
          this.sprite.x > this.sprite.width / 4;

        if (inScreen) {
          this.game.sound
            .add("footstep", {
              volume: 0.07,
            })
            .play();
        }
      },
    });

    this.sprite.play(`${this.key}Walk`);
    this.sprite.setDepth(101);
  }

  stop() {
    this.tweenX.stop();
    this.tweenY.stop();
  }

  end() {
    this.sprite.play(`${this.key}End`);
    this.sprite.setPipeline("pixelPipeline_2");
  }
}
