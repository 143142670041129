import Phaser from 'phaser';

import { GAME_HEIGHT, GAME_WIDTH } from '../app';
import { Emitter } from './emitter';
import { Enemy } from './enemy';
import eventsCenter from './events';
import { Hero } from './hero';
import { World } from './world';

export class GameScene extends Phaser.Scene {
  constructor() {
    super({ key: "GameScene", visible: false, active: false });
    this.handleAction = this.handleAction.bind(this);
  }

  preload() {
    this.alertSound = this.game.sound.add("alert", {
      volume: 0.2,
    });

    this.data.set("game_state", "playing");
    this.overlay = this.add.graphics();
    this.overlay = this.add.rectangle(
      0,
      0,
      GAME_WIDTH * 3,
      GAME_HEIGHT * 3,
      0x00000,
      1
    );
    this.overlay.setDepth(101);
    this.overlay.setAlpha(1);

    this.cameras.main.setRenderToTexture("pixelPipeline_1");
  }

  create() {
    this.state = "playing";
    this.world = new World(this);
    this.hero = new Hero(this);
    this.emitter = new Emitter(this);

    this.enemies = [
      new Enemy(this, "GIULIANI", {
        flip: true,
        direction: "right",
        walk: { length: 4, fps: 12 },
        look: { length: 8, fps: 12 },
        end: { length: 6, fps: 10 },
      }),
      new Enemy(this, "POWELL", {
        flip: false,
        direction: "left",
        walk: { length: 4, fps: 12 },
        end: { length: 4, fps: 7 },
      }),
    ];

    this.hero.sprite.on(
      "animationcomplete",
      (anim) => {
        if (anim.key === "throw") this.hero.sleep();
      },
      this.hero.sprite
    );

    this.startGame();

    this.time.addEvent({
      delay: 2500,
      callback: () => {
        this.input.keyboard.on("keyup_SPACE", this.handleAction);
        this.input.on("pointerup", this.handleAction);
      },
    });

    this.tweens.addCounter({
      from: 1,
      to: 0,
      duration: 800,
      ease: "Sine.easeInOut",
      onUpdate: (tween) => {
        const value = tween.getValue();
        this.overlay.setAlpha(value);
        this.game.renderer.getPipeline("pixelPipeline_1").pixelHeight =
          value * 50;
        this.game.renderer.getPipeline("pixelPipeline_1").pixelWidth =
          value * 50;
      },
      onComplete: () => {
        this.cameras.main.clearRenderToTexture();
        this.hero.pixelate(true);
      },
    });
  }

  handleAction() {
    if (this.data.get("game_state") === "playing") {
      eventsCenter.emit("update-count", this.count);
      this.hero.throw();
      this.emitter.emit();

      this.game.sound
        .add("throw", {
          volume: 0.2,
        })
        .play();

      const inScreenEnemy = this.enemies.find(
        (enemy) =>
          enemy.sprite.x > enemy.sprite.width / 4 &&
          enemy.sprite.x < GAME_WIDTH - enemy.sprite.width / 4
      );

      if (inScreenEnemy !== undefined) {
        this.data.set("game_state", "lost");
        this.alertSound.play();
        this.enemies.forEach((enemy) => enemy.stop());

        this.time.addEvent({
          delay: 1200,
          callback: () => {
            this.sound.play("imthepresident", { volume: 0.2 });
            inScreenEnemy.end();
          },
        });

        this.stopGame(inScreenEnemy.key);

        this.cameras.main.zoomTo(3, 1200);
        this.cameras.main.pan(
          this.cameras.main.centerX -
            (this.cameras.main.centerX - inScreenEnemy.sprite.x),
          this.cameras.main.centerY -
            (this.cameras.main.centerY -
              inScreenEnemy.sprite.y * 1.2 +
              inScreenEnemy.sprite.width / 4),
          1200
        );

        this.cameras.main.shake(1200, 0.01);
      }
    }
  }

  stopGame(key) {
    eventsCenter.emit("game_over", key);

    this.enemies.forEach((enemy) => enemy.stop());

    this.tweens.addCounter({
      from: 0,
      to: 0.8,
      duration: 600,
      onUpdate: (tween) => {
        this.overlay.setAlpha(tween.getValue());
      },
    });

    this.world.stopAll();
    this.hero.stop();
  }

  startGame() {
    this.world.startAll();
    this.hero.start();
  }

  update() {
    if (this.data.get("game_state") === "lost") {
    }
  }
}
