import Phaser from 'phaser';

import { DialogBox } from './DialogBox';
import eventsCenter from './events';

export class UIScene extends Phaser.Scene {
  constructor() {
    super({ key: "UIScene", visible: false, active: false });
    this.cleanUp = this.cleanUp.bind(this);
  }

  preload() {
    this.load.setBaseURL("./assets");

    this.bgMusic = this.game.sound.add("ingame", {
      volume: 1,
      loop: true,
    });

    this.gameOverMusic = this.game.sound.add("gameover", {
      volume: 0.2,
      loop: true,
    });
  }
  create() {
    this.data.set("game_state", "playing");
    this.data.set("scoreTrump", 60);
    this.data.set("scoreBiden", 0);
    this.data.set("delta", 0);

    this.trumpLeading = true;
    this.bidenLeading = false;

    this.bgMusic.play({ volume: 0.1, loop: true });

    this.scoreboard = this.add.sprite(1290, 70, "gems", "MISC/scoreboard.png");

    this.scoreBlue = this.add.sprite(1020, 67, "gems", "MISC/scoreblue.png");
    this.scoreRed = this.add.sprite(1550, 65, "gems", "MISC/scorered.png");
    this.scorebar = this.add.sprite(1290, 67, "gems", "MISC/scorebar.png");

    this.scoreboard.setScale(1.5);
    this.scorebar.setScale(1.5);

    this.scoreBlue.setOrigin(0, 0.5);
    this.scoreBlue.setScale(0, 1.5);

    this.scoreRed.setOrigin(1, 0.5);
    this.scoreRed.setScale(1, 1.5);

    this.scoreTrumpText = this.add.text(1405, 46, "", {
      font: "43px VT323",
      fill: "#ffffff",
    });

    this.scoreBidenText = this.add.text(1026, 46, "", {
      font: "43px VT323",
      fill: "#ffffff",
    });

    this.retryText = this.add.text(
      406,
      800,
      "- click or press space to try again -",
      {
        font: "60px VT323",
        fill: "#ffffff",
      }
    );
    this.retryText.setInteractive();
    this.retryText.setAlpha(0);

    this.dialogBox = new DialogBox(this);
    this.scoreDialog = new DialogBox(this);

    this.scoreTrumpText.setAlpha(0.8);
    this.scoreBidenText.setAlpha(0.8);

    this.counterListener = eventsCenter.on(
      "update-count",
      () => {
        this.data.set(
          "scoreTrump",
          Math.max(0, this.data.get("scoreTrump") - 1)
        );
      },
      this
    );

    this.gameListener = eventsCenter.once(
      "game_over",
      (data) => {
        this.bgMusic.stop();
        this.gameOverMusic.play();
        this.data.set("game_state", "game_over");
        this.dialogBox.show(
          data === "POWELL" ? ["Sidney Powell"] : ["Rudy Giuliani"],
          data === "POWELL"
            ? ["Game over Joe.", "RELEASE THE KRAKEN", "", ""]
            : ["You got served Joe !", "Look at all those affidavits..", "", ""]
        );
        this.time.addEvent({
          delay: 3200,
          callback: () => {
            this.createEventPool();
            this.retryTimer = this.time.addEvent({
              delay: 360,
              loop: true,
              callback: () => {
                this.retryText.setAlpha(this.retryText.alpha === 0 ? 1 : 0);
              },
            });
          },
        });
      },
      this
    );

    this.scoreDialog.hide();
    this.scoreDialog.show(
      ["Fake News Media"],
      [
        "TRUMP IS LEADING",
        "Press space or click anywhere",
        "to dump some ballots",
      ],
      true
    );

    this.comeoneTimer = this.time.addEvent({
      delay: 10000,
      repeat: -1,
      callback: () => {
        this.sound.play("comeonman", { volume: 0.2 });
      },
    });
  }

  createEventPool() {
    this.input.on("pointerup", this.cleanUp);
    this.input.keyboard.on("keydown_SPACE", this.cleanUp);
  }

  removeEventPool() {
    this.input.off("pointerup", this.cleanUp);
    this.input.keyboard.off("keydown_SPACE", this.cleanUp);
  }

  cleanUp() {
    this.removeEventPool();
    this.scene.get("PreloadScene").destroyGame();

    this.bgMusic.stop();
    this.gameOverMusic.stop();

    this.game.sound
      .add("transition", {
        volume: 0.04,
      })
      .play();
  }

  toggleElements(show) {
    if (!show) {
      this.comeoneTimer.destroy();
      this.scoreDialog.hide();
    }

    this.scoreBlue.setAlpha(show ? 1 : 0);
    this.scoreRed.setAlpha(show ? 1 : 0);
    this.scoreboard.setAlpha(show ? 1 : 0);
    this.scorebar.setAlpha(show ? 1 : 0);
    this.scoreTrumpText.setAlpha(show ? 1 : 0);
    this.scoreBidenText.setAlpha(show ? 1 : 0);
  }

  update(_, delta) {
    const DELTA_MAX = 7;
    if (
      this.data.get("scoreTrump") > this.data.get("scoreBiden") &&
      !this.trumpLeading &&
      !this.scoreDialog.active
    ) {
      this.trumpLeading = true;
      this.bidenLeading = false;
      this.scoreDialog.hide();
      this.scoreDialog.show(
        ["FOX NEWS"],
        ["TRUMP IS LEADING !", "", "We might as well call Arizona.."],
        true
      );
    }

    if (
      this.data.get("scoreBiden") > this.data.get("scoreTrump") &&
      !this.bidenLeading &&
      !this.scoreDialog.active
    ) {
      this.trumpLeading = false;
      this.bidenLeading = true;
      this.scoreDialog.hide();
      this.scoreDialog.show(
        ["CNN"],
        [
          "BIDEN TAKES THE LEAD",
          "Keep on dumping those ballots !",
          "Come on man !",
        ],
        true
      );
    }

    if (this.data.get("game_state") === "playing") {
      this.toggleElements(true);

      this.data.set("delta", (this.data.get("delta") + delta) % DELTA_MAX);

      if (this.data.get("delta") > DELTA_MAX - 1) {
        this.data.set(
          "scoreTrump",
          this.data.get("scoreTrump") + (Math.random() > 0.27 ? 1 : 0)
        );
        this.data.set(
          "scoreBiden",
          this.data.get("scoreBiden") + (Math.random() > 0.47 ? 1 : 0)
        );
      }

      this.scoreBidenText.setText(
        this.data.get("scoreBiden").toString().padStart(8, "0")
      );
      this.scoreTrumpText.setText(
        this.data.get("scoreTrump").toString().padStart(8, "0")
      );

      const total = this.data.get("scoreTrump") + this.data.get("scoreBiden");
      const partTrump = this.data.get("scoreTrump") / total;
      const partBiden = this.data.get("scoreBiden") / total;

      this.scorebar.setX(partBiden * (1550 - 1020) + 1020);
      this.scoreBlue.setScale(partBiden * 132, 1.5);
      this.scoreRed.setScale(partTrump * 132, 1.5);
    } else {
      this.toggleElements(false);
    }
  }
}
