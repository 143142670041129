export class TypeWriter {
  constructor(game, config) {
    this.game = game;
    this.wordDelay = 80;
    this.lineDelay = 200;
    this.enableSound = true;
    this.content = [];
    this.text = this.game.add.text(config.x, config.y, "", {
      font: config.font,
      fill: config.fill,
      ...(config.extra ?? {}),
    });
  }

  nextLine() {
    if (this.lineIndex === this.content.length) return;
    this.line = this.content[this.lineIndex].split(" ");

    this.wordIndex = 0;
    this.timer = this.game.time.addEvent(
      {
        delay: this.wordDelay,
        repeat: this.line.length - 1,
        callback: () => {
          this.nextWord();
        },
      },
      this
    );
    this.lineIndex++;
  }

  nextWord() {
    if (this.line[this.wordIndex] !== "" && this.enableSound)
      this.game.sound
        .add("type", {
          volume: 0.03,
          loop: false,
        })
        .play();

    this.text.text = this.text.text.concat(this.line[this.wordIndex] + " ");
    this.wordIndex++;

    if (this.wordIndex === this.line.length) {
      this.text.text = this.text.text.concat("\n");

      this.timer = this.game.time.addEvent(
        {
          delay: this.lineDelay,
          callback: () => this.nextLine(),
        },
        this
      );
    }
  }

  start(content, delay) {
    this.text.text = "";
    this.wordIndex = 0;
    this.lineIndex = 0;
    this.line = [];

    this.content = content;
    this.timer = this.game.time.addEvent({
      delay,
      callback: () => this.nextLine(),
    });
  }

  reset() {
    this.timer !== undefined && this.timer.destroy();
    this.text.text = "";
    this.wordIndex = 0;
    this.lineIndex = 0;
    this.line = [];
  }

  stop() {
    this.enableSound = false;
  }
}
