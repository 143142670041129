import Phaser from 'phaser';
import PixelationPipelinePlugin from 'phaser3-rex-plugins/plugins/pixelationpipeline-plugin.js';
import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin.js';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';

import { GameScene } from './src/gameScene';
import { PreloadScene } from './src/PreloadScene';
import { UIScene } from './src/UIScene';

export const GAME_WIDTH = 1675;
export const GAME_HEIGHT = 901;

const config = {
  type: Phaser.AUTO,
  width: GAME_WIDTH,
  height: GAME_HEIGHT,
  scale: {
    mode: Phaser.Scale.FIT,
    parent: "phaser-example",
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  plugins: {
    global: [
      {
        key: "rexShakePosition",
        plugin: ShakePositionPlugin,
        start: true,
      },
      {
        key: "rexWebFontLoader",
        plugin: WebFontLoaderPlugin,
        start: true,
      },
      {
        key: "rexPixelationPipeline",
        plugin: PixelationPipelinePlugin,
        start: true,
      },
    ],
  },
  scene: [PreloadScene, GameScene, UIScene],
};

new Phaser.Game(config);
