import { TypeWriter } from './typewrite';

export class DialogBox {
  constructor(game) {
    this.game = game;
    this.dialogBox = this.game.add.sprite(310, 160, "gems", "MISC/text.png");
    this.dialogBox.setScale(0.7);
    this.dialogBox.setAlpha(0);

    this.isActive = false;
    this.setup();
  }

  setup() {
    this.endTextTitle = new TypeWriter(this.game, {
      x: 48,
      y: 40,
      font: "37px VT323",
      fill: "#000000",
    });

    this.endText = new TypeWriter(this.game, {
      x: 65,
      y: 110,
      font: "34px VT323",
      fill: "#ffffff",
    });
  }

  hide() {
    this.tween !== undefined && this.tween.stop();
    this.endText.reset();
    this.endTextTitle.reset();
    this.dialogBox.setAlpha(0);
    this.endTextTitle.text.setAlpha(0);
    this.endText.text.setAlpha(0);
    this.active = false;
  }

  show(title, content, hideAfter) {
    this.hide();
    this.active = true;

    this.tween = this.game.tweens.addCounter({
      from: 0,
      to: 1,
      delay: 1400,
      duration: 500,
      ease: "Back",
      easeParams: [3.5],
      onStart: () => {
        this.game.sound
          .add("dialogin", {
            volume: 0.04,
          })
          .play();
      },
      onUpdate: (tween) => {
        this.dialogBox.setX(tween.getValue() * 310);
        this.dialogBox.setAlpha(tween.getValue() * 0.8);
        this.endTextTitle.text.setX(tween.getValue() * 48);
        this.endTextTitle.text.setAlpha(tween.getValue());
        this.endText.text.setX(tween.getValue() * 65);
        this.endText.text.setAlpha(tween.getValue());
      },
      onComplete: () => {
        this.endTextTitle.start(title, 0);
        this.endText.start(content, 800);
        if (!hideAfter) this.active = false;

        if (hideAfter) {
          this.tween = this.game.tweens.addCounter({
            from: 1,
            to: 0,
            delay: 4500,
            duration: 500,
            ease: "Back",
            easeParams: [3.5],
            onUpdate: (tween) => {
              this.dialogBox.setX(tween.getValue() * 310);
              this.dialogBox.setAlpha(tween.getValue() * 0.8);
              this.endTextTitle.text.setX(tween.getValue() * 48);
              this.endTextTitle.text.setAlpha(tween.getValue());
              this.endText.text.setX(tween.getValue() * 65);
              this.endText.text.setAlpha(tween.getValue());
            },
            onComplete: () => {
              this.active = false;
            },
          });
        }
      },
    });
  }
}
