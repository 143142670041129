export class World {
  constructor(game) {
    this.game = game;
    this.setup();
  }

  setup() {
    this.bg = this.game.add.image(1675 / 2, 901 / 2, "sky");
    /* repeat bg */
    this.bgRight = this.game.add.image((1675 / 2) * 3, 901 / 2, "sky");
    this.bgLeft = this.game.add.image(-(1675 / 2), 901 / 2, "sky");
    this.bgRight.setScale(-1, 1);
    this.bgLeft.setScale(-1, 1);

    this.truck = this.game.add.sprite(1150, 655, "gems", "TRUCK/truck0000.png");
    this.pile = this.game.add.sprite(230, 790, "gems", "BALLOT/pile.png");

    const rumbleFrames = this.game.anims.generateFrameNames("gems", {
      start: 0,
      end: 2,
      zeroPad: 4,
      prefix: "TRUCK/truck",
      suffix: ".png",
    });

    this.game.anims.create({
      key: "rumble",
      frames: rumbleFrames,
      frameRate: 17,
      repeat: -1,
    });

    this.truck.anims.play("rumble");
    this.pile.setDepth(100);
  }

  stopAll() {
    this.truck.anims.pause();
  }

  startAll() {
    this.truck.anims.play("rumble");
  }
}
