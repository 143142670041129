import Phaser from 'phaser';

import { GAME_HEIGHT, GAME_WIDTH } from '../app';
import { TypeWriter } from './typewrite';

export class PreloadScene extends Phaser.Scene {
  constructor() {
    super({ key: "PreloadScene", active: true, visible: true });
    this.startGame = this.startGame.bind(this);
  }

  preload() {
    this.load.rexWebFont({
      google: {
        families: ["VT323"],
      },
    });

    this.load.setBaseURL("./assets");
    this.load.image("sky", "./background.png");
    this.load.multiatlas("gems", "./gems.json");
    this.load.audio("footstep", ["audio/footstep.mp3"]);
    this.load.audio("throw", ["audio/throw.mp3"]);
    this.load.audio("bg", ["audio/bg.mp3"]);
    this.load.audio("alert", ["audio/alert.mp3"]);
    this.load.audio("transition", ["audio/transition.mp3"]);
    this.load.audio("ingame", ["audio/ingame.mp3"]);
    this.load.audio("gameover", ["audio/gameover.mp3"]);
    this.load.audio("type", ["audio/type.mp3"]);
    this.load.audio("dialogin", ["audio/dialogin.mp3"]);
    this.load.audio("snore", ["audio/snore.mp3"]);
    this.load.audio("imthepresident", ["audio/imthepresident.mp3"]);
    this.load.audio("comeonman", ["audio/comeonman.mp3"]);

    const progressBar = this.add.graphics().setDepth(250);
    const progressBox = this.add.graphics().setDepth(250);
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(650, 400, 320, 50);

    this.overlay = this.add.graphics();
    this.overlay.fillStyle(0x000000, 1);
    this.overlay.fillRect(0, 0, GAME_WIDTH, GAME_HEIGHT);
    this.overlay.setDepth(150);
    this.overlay.setAlpha(1);

    this.load.on("progress", (value) => {
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRect(660, 410, 300 * value, 30);
    });

    this.load.on("complete", () => {
      progressBar.destroy();
      progressBox.destroy();
      this.prestartText = this.add
        .text(480, 390, "- click anywhere to start -", {
          font: "60px VT323",
          fill: "#ffffff",
        })
        .setDepth(200);

      this.textTimer = this.time.addEvent({
        delay: 360,
        loop: true,
        callback: () => {
          this.prestartText.setAlpha(this.prestartText.alpha === 0 ? 1 : 0);
        },
      });

      this.input.once("pointerup", () => {
        let audioContext;
        try {
          audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
        } catch (e) {
          console.error(e);
        }
        audioContext.resume();
        this.sound.setAudioContext(audioContext);

        if (this.sound?.context?.state === "suspended") {
          this.sound.context.resume();
        }

        this.prestartText.destroy();
        this.textTimer.destroy();
        this.setup();
      });
    });

    this.pipelineInstance = this.plugins
      .get("rexPixelationPipeline")
      .add(this, "pixelPipeline", { pixelHeight: 0, pixelWidth: 0 });

    this.game.renderer.addPipeline("pixelPipeline_1", this.pipelineInstance);
    this.cameras.main.setRenderToTexture("pixelPipeline_1");

    /* SECOND PIPELINE FOR INGAME OBJECTS */
    this.pipelineInstance2 = this.plugins
      .get("rexPixelationPipeline")
      .add(this, "pixelPipeline_bis", { pixelHeight: 4, pixelWidth: 4 });

    this.game.renderer.addPipeline("pixelPipeline_2", this.pipelineInstance2);
  }

  resize() {
    const canvas = document.getElementsByTagName("canvas")[0];
    let height;
    let width;

    const ratio = canvas.height / canvas.width;
    const windowHeight = Math.min(window.innerHeight, 480) - 40;
    const windowWidth = Math.min(window.innerWidth, 1460);
    const windowRatio = windowHeight / windowWidth; /* browser size */

    if (windowRatio < ratio) {
      height = windowHeight;
      width = windowHeight / ratio;
    } else {
      height = windowWidth * ratio;
      width = windowWidth;
    }

    canvas.style.height = `${height}px`;
    canvas.style.width = `${width}px`;
  }

  create() {}

  setup() {
    this.logo = this.add.sprite(819, 250, "gems", "MISC/logo.png");
    this.bgAudio = this.game.sound.add("bg", {
      volume: 0.1,
      loop: true,
    });

    this.tweens.add({
      duration: 1000,
      yoyo: true,
      loop: true,
      repeat: -1,
      ease: "Sine.easeInOut",
      targets: this.logo,
      y: 240,
    });

    this.startText = this.add.text(
      453,
      700,
      "- click or press space to play -",
      {
        font: "60px VT323",
        fill: "#ffffff",
      }
    );

    this.startText.setAlpha(1);

    this.summary = new TypeWriter(this, {
      x: 474,
      y: 520,
      font: "37px VT323",
      fill: "#FFFFFF",
    });

    this.summary.start(
      [
        "November 4th 2020, 3:00 AM - it's election night.",
        "    Help Sleepy Joe dump some Trump votes !",
        "          Watch out for Rudy & Sidney",
      ],
      700
    );

    this.setupSocialIcons();
    this.createEventPool();
    this.splashScreenStart();
  }

  startGame(_, targets) {
    if (targets?.length > 0) {
      const target = targets[0].name;

      switch (target) {
        case "facebook": {
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
              "https://helpjoe.io"
            )}&t=${"Help Joe dump some ballots!"}`,
            "",
            "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
          );
          break;
        }
        case "twitter": {
          window.open(
            `https://twitter.com/share?url=${encodeURI(
              "https://helpjoe.io"
            )}&via=HelpJoeG&text=${"Help Joe dump some ballots! #HelpJoe"}`,
            "",
            "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
          );
          break;
        }
        case "donate": {
          window.open(
            "https://commerce.coinbase.com/checkout/a3d6772b-0c4a-44d1-b64c-5db15eb077f0"
          );
          break;
        }
        default:
          break;
      }
      return;
    }

    this.bgAudio.stop();

    this.removeEventPool();
    const ui = this.scene.get("UIScene");
    const game = this.scene.get("GameScene");
    this.summary.stop();
    this.game.sound
      .add("transition", {
        volume: 0.04,
      })
      .play();

    this.tweens.addCounter({
      from: 0,
      to: 1,
      duration: 800,
      ease: "Sine.easeInOut",
      onUpdate: (tween) => {
        const value = tween.getValue();
        this.overlay.setAlpha(value);
        this.pipelineInstance.pixelHeight = value * 50;
        this.pipelineInstance.pixelWidth = value * 50;
      },
      onComplete: () => {
        ui.scene.restart();
        game.scene.restart();
        this.textTimer.remove();
      },
    });
  }

  destroyGame() {
    const ui = this.scene.get("UIScene");
    const game = this.scene.get("GameScene");

    ui.scene.sleep();
    game.scene.sleep();
    ui.scene.setVisible(false);
    game.scene.setVisible(false);

    this.time.addEvent({
      delay: 500,
      callback: () => {
        this.createEventPool();
        this.splashScreenStart();
      },
    });
  }

  splashScreenStart() {
    this.bgAudio.play();
    this.tweens.addCounter({
      from: 1,
      to: 0,
      duration: 800,
      ease: "Sine.easeInOut",
      onUpdate: (tween) => {
        const value = tween.getValue();
        this.overlay.setAlpha(value);
        this.pipelineInstance.pixelHeight = value * 50;
        this.pipelineInstance.pixelWidth = value * 50;
      },
      onComplete: () => {
        this.textTimer = this.time.addEvent({
          delay: 360,
          loop: true,
          callback: () => {
            this.startText.setAlpha(this.startText.alpha === 0 ? 1 : 0);
          },
        });
      },
    });
  }

  createEventPool() {
    this.input.on("pointerup", this.startGame);
    this.input.keyboard.on("keydown_SPACE", this.startGame);
  }

  removeEventPool() {
    this.input.off("pointerup", this.startGame);
    this.input.keyboard.off("keydown_SPACE", this.startGame);
  }
  pauseGame() {
    this.scene.destroy("GameScene");
    this.scene.destroy("UIScene");
  }

  setupSocialIcons() {
    this.fb = this.add
      .sprite(115, 812, "gems", "MISC/facebook.png")
      .setInteractive({ useHandCursor: true });
    this.fb.setScale(0.4);
    this.fb.setName("facebook");

    this.twitter = this.add
      .sprite(215, 812, "gems", "MISC/twitter.png")
      .setInteractive({ useHandCursor: true });
    this.twitter.setScale(0.4);
    this.twitter.setName("twitter");

    this.donate = this.add
      .sprite(1587, 812, "gems", "MISC/donate.png")
      .setInteractive({ useHandCursor: true });
    this.donate.setScale(0.38);
    this.donate.setName("donate");

    this.add.text(61, 850, "Share on Big Tech", {
      font: "30px VT323",
      fill: "#ffffff",
    });

    this.add.text(1551, 850, "Donate", {
      font: "30px VT323",
      fill: "#ffffff",
    });

    this.add
      .text(694, 850, "contact : helpjoe-game@protonmail.com", {
        font: "20px VT323",
        fill: "#ffffff",
      })
      .setAlpha(1);

    this.tweens.add({
      targets: [this.fb, this.donate, this.twitter],
      delay: this.tweens.stagger(100),
      duration: 400,
      yoyo: true,
      repeat: -1,
      ease: "Sine.easeInOut",
      y: 816,
    });
  }
}
